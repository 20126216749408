<template>
  <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div class="flex-shrink-0">
      <img class="h-48 w-full object-cover" src="@/assets/palette_screenshot.png" alt="" />
    </div>
    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1">
        <p class="text-sm font-medium text-indigo-600">
          Free!
        </p>
        <div class="block mt-2">
          <p class="text-xl font-semibold text-gray-900">
            Color Palette
          </p>
          <p class="mt-3 text-base text-gray-500">
            Embed a beautiful color picker and palette. Your team shares the palette.
          </p>
        </div>
      </div>
      <div class="mt-6 flex items-center">
        <div class="flex-shrink-0">
          <button
            v-if="state !== 'twostep'" :disabled="state !== 'ready'" @click="getBlock"
            type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
            <span v-if="state === 'ready'">
              Get it now
            </span>
            <span v-else-if="state === 'done'">
              Link ready!
            </span>
            <span v-else-if="state === 'errored'">
              Error :(
            </span>
            <span v-else>
              Loading...
            </span>
          </button>
          <form v-if="state === 'twostep'">
            <input ref="thelink" v-model="link" type="text">
            <button class="mt-1" @click="copied">
              Copy Custom Link
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()

export default {
  data() {
    return {
      state: 'ready', // 'ready', 'loading, 'done' 'twostep' 'errored'
      link: '',
    }
  },
  methods: {
    copied () {
      console.log('copied')
    },
    getBlock () {
      this.state = 'loading'
      this.axios.post('https://palette-api.blockbase.dev/new').then((response) => {
        this.link = process.env.VUE_APP_URL + 'block/palette/' + response.data.token
        this.state = 'done'
        const t = this
        toClipboard(this.link).then(function () {
          t.copied()
        }, function () {
          t.state = 'twostep'
          this.nextTick(() => {
            t.$refs.thelink.select()
          })
        })
        setTimeout(() => {
          this.state = 'ready'
        }, 10000)
      }, () => {
        this.state = 'errored'
        setTimeout(() => {
          this.state = 'ready'
        }, 8000)
      })
    }
  }
}
</script>
